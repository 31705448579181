import * as React from "react"
import {
    Button, 
    Grid,
    Paper,
} from "@mui/material";

import { Trans } from 'gatsby-plugin-react-i18next';


const lorem = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.";

const defaults = {
    title: "Titulo de Prueba",
    description: lorem,
    buttonText: "Ver",
    clickCallback: () =>{console.log("Card clicked!")},
    disabled: false,
}


const GenericCard = (props) => {
    const title = props.title || defaults.title;
    const description = props.description || defaults.description;
    const buttonText = props.buttonText || defaults.buttonText;
    const clickCallback = props.onClick || defaults.clickCallback;
    const disabled = props.disabled || defaults.disabled;
    return (
        <>

        <Paper elevation={3} style={{height: '100%', padding: '16px', cursor: (disabled) ? '' : 'pointer'}} onClick={(disabled) ? ()=>{} : clickCallback} >
            <Grid container>
                <Grid item xs={12} style={{justifyContent: 'top', backgroundColor: '#fff'}}>
                    <p style={{fontSize: '20px', textAlign: 'center'}}>{title}</p>
                </Grid>
                <Grid item xs={12}>
                    <p style={{fontSize: '12px', color: '#8b8b8b', textAlign: 'justify'}}>{description}</p>
                </Grid>
                <Grid item xs={12} style={{textAlign: 'center', justifyContent: 'center', marginTop: '16px'}}>
                    <Button 
                        sx={{fontSize:'16px', height:'30px'}}
                        variant='contained' 
                        disabled={disabled}
                        onClick={clickCallback}
                    >
                        <Trans>{buttonText}</Trans>
                    </Button>
                </Grid>
            </Grid>
        </Paper>
        </>
    );
}



export default GenericCard