import * as React from "react"
import GenericCard from "../components/GenericCard"
import { graphql, navigate} from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { 
  Backdrop,
  CircularProgress,
  Grid
 } from "@mui/material"
import BeGeneralPage from "../components/be-general-page";
import { AuthContext } from '../contexts/contexts.js';


const newExperimentContent = {
  title: "Create a new experiment",
  description: "Start selecting the games for your experiment",
  buttonText: "Start",
  callback: () =>{navigate('/newExperiment/');}
};

const myProfileContent = {
  title: "Your Profile",
  description: "Review and manage your profile information",
  buttonText: "Manage your profile",
  callback: () =>{navigate('/newExperiment/');}
};

const myExperimentsContent = {
  title: "My created experiments",
  description: "Here you can review your on going or finished experiments, manage them and download answers",
  buttonText: "Go to my experiments",
  callback: () =>{navigate('/myExperiments/');}
};

const administrationContent = {
  title: "Administration dashboard",
  description: "Validate new researchers and manage their access to the games",
  buttonText: "Manage",
  callback: () =>{navigate('/administration/');}
};

// markup
const IndexPage = () => {
    const { t } = useTranslation();
    const authContextValue = React.useContext(AuthContext);

  return (
      <BeGeneralPage>
        <Grid container spacing={2} pt={4} pl={4}>
          <Grid item xs={4} pb={8}>
            <GenericCard
              title={t(myProfileContent.title)}
              description={t(myProfileContent.description)}
              buttonText={t(myProfileContent.buttonText)}
              onClick={myProfileContent.callback}
              disabled
            />
          </Grid>
          <Grid item xs={4} pb={8}>
            <GenericCard
              title={t(newExperimentContent.title)}
              description={t(newExperimentContent.description)}
              buttonText={t(newExperimentContent.title)}
              onClick={newExperimentContent.callback}
            />
          </Grid>
          <Grid item xs={4} pb={8}>
            <GenericCard
              title={t(myExperimentsContent.title)}
              description={t(myExperimentsContent.description)}
              buttonText={t(myExperimentsContent.buttonText)}
              onClick={myExperimentsContent.callback}
            />
          </Grid>
          {
            (authContextValue.userData?.role === 'admin') ?
              <Grid item xs={4} pb={8}>
                <GenericCard
                  title={t(administrationContent.title)}
                  description={t(administrationContent.description)}
                  buttonText={t(administrationContent.buttonText)}
                  onClick={administrationContent.callback}
                />
              </Grid>
            :
              null
          }
        </Grid>
      </BeGeneralPage>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "side_bar", "dashboard"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;